@import '../utilities/breakpoints';
@import '../utilities/mixins';

.countdown {
  background-color: var(--color-primary-2);
  padding: 2rem;
  color: var(--color-white);

  @media only screen and (max-width: $bp-500) {
    padding: 1rem;
    font-size: 1.2rem;
  }

  &_title {
    font-weight: 700;
  }
  &_time {
    color: var(--color-dark);
    background-color: var(--color-primary);
    margin: 1px;
    padding: 2px;
  }
}

// TextArea scss

.form {
  display: block;

  &__group:not(:last-child) {
    margin-bottom: 1rem;
  }

  &__input {
    font-size: 2rem;
    font-family: inherit;
    color: var(--color-dark);
    padding-left: 2rem;
    width: 100%;
    height: 6rem;
    border-radius: 1rem;
    background-color: var(--color-white);
    border: 1px solid var(--color-dark);
    display: block;
    margin-top: 0.5rem;

    &:focus,
    &:focus-visible,
    &:focus-within {
      color: var(--color-dark);
      border: 1px solid blue;
    }

    &_filter {
      font-size: var(--default-font-small);
      font-family: inherit;
      color: var(--color-dark);
      padding-left: 2rem;
      width: 100%;
      height: 4rem;
      border-radius: 1rem;
      background-color: var(--color-white);
      border: 1px solid var(--color-dark);
      display: block;
      margin-top: 0.5rem;

      &:focus,
      &:focus-visible,
      &:focus-within {
        color: var(--color-dark);
        border: 1px solid blue;
      }
    }
  }

  &__label {
    font-size: 1.4rem;
    font-family: inherit;
    padding-left: 2rem;
    box-sizing: border-box;
    width: 38rem;
  }

  &__calendar {
    font-family: inherit;
    font-size: 1.4rem;
    background-color: var(--color-white);
    color: var(--color-dark);
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08);
    border-radius: 1rem;
    border: none;
    width: 25rem;
    height: 27.5rem;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    padding: 5px;

    & > :nth-child(3) {
      background-color: var(--color-dark);
      left: 1rem;
      top: 1rem;
      border-radius: 0.8rem;

      &:hover {
        background-color: var(--color-primary-4);
      }

      span::before {
        right: -0.3rem;
        top: 0.7rem;
      }
    }
    & > :nth-child(4) {
      background-color: var(--color-dark);
      right: 1rem !important;
      top: 1rem;
      border-radius: 0.8rem;
      &:hover {
        background-color: var(--color-primary-4);
      }
      span::before {
        left: -0.3rem;
        top: 0.7rem;
      }
    }

    & > :nth-child(5) {
      width: 100%;
      font-family: 'Montserrat';
      font-size: 1.4rem;
      background-color: var(--color-light);

      & > :nth-child(1) {
        background-color: var(--color-primary-3);
        & > :nth-child(1) {
          font-size: 1.4rem;
          color: var(--color-primary);
        }

        & > :nth-child(3) {
          font-weight: 700;
          color: var(--color-dark);
          display: grid;
          grid-template-columns: repeat(7, 1fr);
        }
      }

      & > :nth-child(2) {
        & > :nth-child(even) {
          background-color: var(--color-primary-7);
        }
        & > * {
          color: var(--color-dark);
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          height: 3rem;
        }
      }
    }

    & > :nth-child(6) {
      font-family: 'Montserrat';
      font-size: var(--default-font-small);
      position: absolute;
      right: -85px;

      & > :nth-child(1) {
        & > * {
          font-size: var(--default-font-xsmall) !important;
        }
      }
    }
  }
}

// When form is filled

.filled {
  color: var(--color-dark);
}

.error {
  font-size: 1rem;
  color: var(--color-error);
  // padding-left: 2rem;
  font-weight: 400;
  margin: 1rem 0;
  max-width: 38rem;
}
