@import '../utilities/breakpoints';

.rb {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: var(--default-font-size);
    gap: 1rem;

    @media only screen and (max-width: $bp-600) {
        font-size: var(--default-font-small);
    }

    &__input {
        display: none;
    }

    &__check {
        width: 3rem;
        height: 3rem;
        border: 2px solid var(--color-primary);
        cursor: pointer;
        border-radius: 50%;
        box-sizing: border-box;
        margin-right: 1rem;
        padding: 3px;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: var(--color-primary);
            display: block;
            border-radius: 50%;

            transform: scale(0);
            transition: transform 0.2s;
        }
    }

    &__input:checked~&__check::after {
        transform: scale(1);
    }

    &:hover &__input~&__check {
        border: 3px solid var(--color-primary-4);
    }
}

.cb {
    display: grid;
    grid-template-columns: 3rem 1fr;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: var(--default-font-size);
    gap: 1rem;
    // width: 100%;
    width: fit-content;

    @media only screen and (max-width: $bp-600) {
        font-size: var(--default-font-small);
    }

    &__input {
        display: none;
    }

    &__check {
        width: 3rem;
        height: 3rem;
        border: 2px solid var(--color-primary);
        cursor: pointer;
        border-radius: 0.6rem;
        box-sizing: border-box;
        margin-right: 1rem;
        padding: 3px;

        &::after {
            content: '';
            width: 100%;
            height: 100%;
            background-color: var(--color-primary);
            display: block;
            border-radius: 0.3rem;

            transform: scale(0);
            transition: transform 0.2s;
        }
    }

    &__input:checked~&__check::after {
        transform: scale(1);
    }

    &:hover &__input~&__check {
        border: 3px solid var(--color-primary-4);
    }
}

.note {
    margin-top: 2rem;
    background-color: var(--color-table-grey);
    padding: 1rem;
    display: grid;
    grid-template-columns: 2.4rem 1fr;
    gap: 1rem;
    cursor: pointer;

    &__icon {
        color: var(--color-primary);
        font-size: 2.4rem;

        &:hover {
            color: var(--color-primary-4);
        }
    }
}