@import '../utilities/breakpoints';

.background {
	width: 100vw;
	height: 100vh;
	background-color: rgba(#2a2828, 0.7);
	position: fixed;
	display: grid;
	grid-template-columns: 65vw 35vw;
	z-index: 100;

	@media only screen and (max-width: $bp-1000) {
		grid-template-columns: 50vw 50vw;
	}

	@media only screen and (max-width: $bp-800) {
		grid-template-columns: 35vw 65vw;
	}

	@media only screen and (max-width: $bp-500) {
		grid-template-columns: 100vw;
		grid-template-rows: 1fr max-content;
	}
}

.container {
	width: 100%;
	min-height: 100vh;
	background-color: var(--color-white);
	box-sizing: border-box;
	box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
	padding: 3rem;
	position: relative;
	bottom: 0;
	grid-column: 2/3;
	overflow-y: scroll;
	overflow-x: hidden;

	&::-webkit-scrollbar {
		width: 1rem;
		background-color: var(--color-white);
		border-radius: 0 1rem 1rem 0;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--color-grey);
		border-radius: 1rem;
	}

	@media only screen and (max-width: $bp-500) {
		grid-column: 1/1;
		border-radius: 5rem 5rem 0 0;
		min-height: 30vh;
		max-height: 85vh;
	}

	&__cover {
		border-radius: 5rem 5rem 0 0;
		overflow-y: hidden;
	}

	&__top {
		position: fixed;
		// background-color: var(--color-white);
		font-size: 5rem;
		display: flex;
		justify-content: space-between;
		z-index: 10;
		height: 7rem;

		&> :first-child {
			cursor: pointer;

			&:hover {
				color: var(--color-red);
			}
		}
	}
}

.bws {
	content: '';
	background-color: var(--color-white);
	width: 100%;
	height: 2rem;
}

.content {
	min-height: 75vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	@media only screen and (max-width: $bp-500) {
		margin: 8rem 0 8rem 0;
		min-height: fit-content;
	}
}

.close {
	position: fixed;
	right: 3rem;
	cursor: pointer;
}