@import '../utilities/breakpoints';
@import '../utilities/mixins';

.carousel {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .carousel_arrowLeft,
  .carousel_arrowRight {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--color-dark);
    border: none;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    padding: 0.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  .carousel_arrowLeft {
    left: 10px;
  }

  .carousel_arrowRight {
    right: 10px;
  }
}
