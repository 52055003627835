@import '../utilities/breakpoints';

.header1,
.header2 {
  display: flex;
  flex-direction: column;
  padding: 2rem 5rem;
  z-index: 1000;
  width: 100%;
  transition: all 300ms ease-in-out;
  @media only screen and (max-width: $bp-600) {
    padding: 1rem 2rem;
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 900;
    img {
      width: 7.2rem;
    }

    .navMenu {
      display: flex;
      align-items: center;
      list-style: none;
      gap: 2.5rem;

      @media only screen and (max-width: $bp-1000) {
        display: none;
      }

      li {
        font-size: 1.6rem;
        cursor: pointer;
        line-height: 2.4rem;
        font-weight: 400;
        transition: all 200ms ease-in-out;

        &:hover {
          font-weight: 700;
          color: var(--color-primary-2);
          transition: all 200ms ease-in-out;
        }
      }
    }

    .btnSet {
      display: flex;
      gap: 2.5rem;

      @media only screen and (max-width: $bp-1000) {
        display: none;
      }
    }

    .hamburger {
      display: none;
      cursor: pointer;
      font-size: 4.5rem;

      &:hover {
        color: var(--color-primary-2);
      }

      @media only screen and (max-width: $bp-1000) {
        display: block;
      }
    }
  }
  .mobileNav {
    display: flex;
    padding: 1rem 0rem;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;
    align-self: stretch;
    z-index: 500;

    height: calc(100dvh - 6.5rem);
    background-color: var(--color-white);
    .mobileNaveMenu {
      min-height: 50vh;
      display: flex;
      flex-direction: column;
      font-size: 2rem;
      text-align: center;
      justify-content: space-evenly;
      list-style: none;
    }
    .btns {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.header1 {
  position: fixed;
  top: 0;
  left: 0;
  &.top {
    background-color: transparent;
    color: var(--color-white);
  }

  &.visible {
    transform: translateY(0);
    opacity: 1;
    background-color: var(--color-white);
    color: var(--color-dark);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    img {
      filter: invert(100%);
    }
    .nav__open {
      color: var(--color-dark);
    }
  }
}
.header2 {
  position: static;

  &.top {
    background-color: var(--color-white);
    color: var(--color-dark);
  }

  &.visible {
    position: fixed;
    transform: translateY(0);
    opacity: 1;
    background-color: var(--color-white);
    color: var(--color-dark);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    .nav__open {
      color: var(--color-dark);
    }
  }
}

.footer {
  background: var(--color-primary-5);
  .container {
    width: 100%;
    max-width: 1440px;
    margin: 0px auto;
    display: flex;
    gap: 3.2rem;
    padding: 13.6rem 6.6rem;
    justify-content: space-between;
    align-content: flex-start;
    @media only screen and (max-width: $bp-800) {
      flex-direction: column-reverse;
      padding: 4rem 2rem;
    }

    .group1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 3.2rem;
      align-self: stretch;
      max-width: 68.3rem;
  
      @media only screen and (max-width: $bp-800) {
        max-width: 100%;
        align-items: center;

      }

      nav {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 6.4rem;
        @media only screen and (max-width: $bp-800) {
          flex-direction: column;
          align-items: center;
          gap: 4rem;
        }
        .linkSet {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 1.2rem;
          // width: 13rem;
          @media only screen and (max-width: $bp-800) {
            text-align: center;
            align-items: center;
          }

          .title {
            font-size: 1.8rem;
            font-style: normal;
            font-weight: 500;
            line-height: 2rem;
         
          }
          a {
            font-size: 1.7rem;
            font-style: normal;
            font-weight: 400;
            line-height: 2rem;
          }
        }
      }
      .description {
        align-self: stretch;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        @media only screen and (max-width: $bp-800) {
          display: none;
        }
      }
    }
    .group2 {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 2.4rem;
      @media only screen and (max-width: $bp-800) {
        align-items: center;
      }

      p {
        text-align: right;
        font-size: 1.8rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2rem;
        @media only screen and (max-width: $bp-800) {
          text-align: center;
        }
      }
      .socialLinks {
        display: flex;
        gap: 32px;
        .socialIcon {
          font-size: 2.4rem; /* Set to your desired size */
        }
      }
    }
  }
}
