@import '../utilities/breakpoints';

.numberedPage {
    width: 3rem;
    height: 3rem;
    font-size: var(--default-font-small);
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: var(--color-white);

    &:hover {
        border: 1px solid #2267ce;
        color: var(--color-primary);
    }


    &:disabled {
        color: var(--color-grey);
        border: 1px solid var(--color-grey);

    }
}

.numberPageActive {
    border: 1px solid #2267ce;
    color: var(--color-primary);
    box-shadow: 0px 0px 7px rgba(34, 103, 206, 0.5);
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    width: 100%;

    &> :not(:last-child) {
        margin-right: 1rem;
    }
}