@import '../../utilities/breakpoints';

.container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    min-height: 100vh;
    width: 100%;

    @media only screen and (max-width: $bp-800) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-image: url('../../../img/pictures/main auth.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding-bottom: 5rem;
    }
}

.content {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    background-image: url('../../../img/pictures/main auth.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 40%;
    padding: 8rem 5rem;

    @media only screen and (max-width: $bp-800) {
        background-image: none;
        align-items: center;
    }
}

.fashIcon {
    width: 12rem;
    cursor: pointer;

    @media only screen and (max-width: $bp-800) {
        margin: 0;
    }
}

.welcome {
    padding: 3rem;
    background-color: rgba(255, 255, 255, 0.65);
    width: 100%;

    @media only screen and (max-width: $bp-800) {
        width: fit-content;
        margin: 2rem;
    }
}

.children {
    width: 100%;
    height: 100%;
    background-color: var(--color-white);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12px 40px;

    @media only screen and (max-width: $bp-800) {
        width: 60rem;
        border-radius: 1rem;
        padding: 3rem;
    }

    @media only screen and (max-width: $bp-600) {
        width: 48rem;
    }

    @media only screen and (max-width: $bp-400) {
        width: fit-content;
    }
}


//Login
.logintext {
    &__center {
        text-align: center;
    }

    &__title {
        font-size: var(--default-font-subtitle);
        font-weight: 700;
        color: var(--color-primary);
        line-height: 3rem;
    }

    &__p {
        font-size: var(--default-font-small);
        font-weight: 400;

        line-height: 1.7rem;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
}


.logInForm {
    display: flex;
    width: 90%;
    height: 80%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.mt_40 {
    margin-top: 4rem;
}

.account {
    &__desc {
        font-size: var(--default-font-small);
        width: 38rem;
        text-align: center;
        margin-bottom: 3rem;

        span {
            font-weight: 700;
            color: var(--color-primary-2);
        }
    }
}

.clickable {
    color: var(--color-primary-2);
    cursor: pointer;

    &:hover {
        font-weight: 700;
    }
}