@import '../utilities/breakpoints';

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.container {
  position: relative;
  background: white;
  border-radius: 8px;
  width: 80%;
  max-width: 500px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.container__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.container__top h4 {
  font-size: 1.25rem;
  font-weight: bold;
}

.close {
  cursor: pointer;
}

.content {
  padding-top: 20px;
}
